import * as React from "react"

// markup
const NotFoundPage = () => {
  return (
    <main>Not found :(</main>
  )
}

export default NotFoundPage
